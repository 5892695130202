export default {
    //智能客服-会话列表
    GET_TALK_LIST: '/api/admin/aisbot/talk/list',
    //智能客服-会话消息列表
    GET_AISBOT_MESSAGE_LIST: '/api/admin/aisbot/message/list',
    //智能客服-发送会话消息
    SEND_AISBOT_MESSAGE: '/api/admin/aisbot/message/send',
    //分析报表-客服分析
    GET_AISBOT_REPORT: '/api/admin/aisbot/report/list',

    //智能客服-媒体账户列表 
    GET_MEMBER_LIST: '/api/admin/aisbot/member/list',
    //智能客服-绑定接待人
    SET_MEMBER_BIND: '/api/admin/aisbot/member/bind',
    //智能客服-解绑接待人
    SET_MEMBER_UNBIND: '/api/admin/aisbot/member/unbind',
    //智能客服-接待人分级列表
    GET_AISBOT_TALK_SERVICE: '/api/admin/aisbot/talk/service/select',

    //智能客服-C端客户详情
    GET_AISBOT_USER_INFO: '/api/admin/aisbot/user/info',
    //智能客服-C端客户编辑
    UPDATE_AISBOT_USER_INFO: '/api/admin/aisbot/user/update',

    //智能客服-固定标签列表
    GET_AISBOT_TAGS_LIST: '/api/admin/aisbot/tags/list',
    //智能客服-固定标签添加
    CREATE_AISBOT_TAG: '/api/admin/aisbot/tags/create',
    //智能客服-固定标签编辑
    UPDATE_AISBOT_TAG: '/api/admin/aisbot/tags/update',
    //智能客服-固定标签删除
    DELETE_AISBOT_TAG: '/api/admin/aisbot/tags/delete',

    //企微会话内容存档-会话列表
    GET_MSGAUDIT_TALK_LIST: '/api/admin/msgaudit/talk/list',
    //企微会话内容存档-消息列表
    GET_MSGAUDIT_MESSAGE_LIST: '/api/admin/msgaudit/message/list',

    //智能客服-会话门店查询列表
    GET_AISBOT_DEALERS: '/api/admin/aisbot/talk/dealer/list',
    //智能客服-会话客服查询列表
    GET_AISBOT_KEFU: '/api/admin/aisbot/talk/kfuser/list',
    //智能客服-会话b端账号查询列表
    GET_AISBOT_ACCOUNT: '/api/admin/aisbot/talk/member/list',

    //智能客服-自动回复开关
    SWITCH_AUTOREPLY_STATE: '/api/admin/aisbot/autoreply/switch',
    //AI模型配置-每个通道都可配置
    INFO_COZE_AISBOT: '/api/admin/aimodel/info',//-【此接口已废弃】
    UPDATE_COZE_AISBOT: '/api/admin/aimodel/update',//-【此接口已废弃】
    //智能客服-配置扣子智能体 -只有主机端才能使用，不正确的智能体id会返回错误
    // UPDATE_COZE_AISBOT: '/api/admin/aisbot/coze/update',
    ROLL_LOAD_DMSS: '/api/admin/aisbot/trials/message/news',
    GET_DMSS_LIST: '/api/admin/aisbot/trials/message/list',
    SEND_AIBOT_DMSS: '/api/admin/aisbot/trials/message/send',

    //AI模型配置-获取映射表
    AI_MODEL_CONFIG:'/api/admin/aimodel/mapping',
    //AI模型配置-点选
    AI_MODEL_UPDATE:'/api/admin/aimodel/change',
}
  